@import (reference) '~antd/dist/antd.less';

.x-large-title-mixin {
    font-size: 54px;
    line-height: 58px;
    font-weight: 400;
    color: @text-high-contrast;
}
.text-x-large-title {
    .x-large-title-mixin();
}
.text-x-large-title-bold {
    .x-large-title-mixin();
    font-weight: 700;
}


.large-title-mixin {
    font-size: 36px;
    line-height: 41px;
    font-weight: 400;
    color: @text-high-contrast;
}
.text-large-title {
    .large-title-mixin();
}
.text-large-title-bold {
    .large-title-mixin();
    font-weight: 700;
}


.title-1-mixin {
    font-size: 32px;
    line-height: 34px;
    font-weight: 400;
    color: @text-high-contrast;
}
.text-title-1 {
    .title-1-mixin();
}
.text-title-1-bold {
    .title-1-mixin();
    font-weight: 700;
}


.title-2-mixin {
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    color: @text-high-contrast;
}
.text-title-2 {
    .title-2-mixin();
}
.text-title-2-bold {
    .title-2-mixin();
    font-weight: 700;
}


.title-3-mixin {
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: @text-high-contrast;
}
.text-title-3 {
    .title-3-mixin();
}
.text-title-3-bold {
    .title-3-mixin();
    font-weight: 700;
}


.headline-mixin {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    color: @text-high-contrast;
}
.text-headline {
    .headline-mixin();
}
.text-headline-bold {
    .headline-mixin();
    font-weight: 700;
}


.callout-mixin {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: @text-high-contrast;
}
.text-callout {
    .callout-mixin();
}
.text-callout-bold {
    .callout-mixin();
    font-weight: 700;
}


.body-mixin {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: @text-high-contrast;
}
.text-body {
    .body-mixin();
}
.text-body-bold {
    .body-mixin();
    font-weight: 700;
}


.footnote-mixin {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: @text-high-contrast;
}
.text-footnote {
    .footnote-mixin();
}
.text-footnote-bold {
    .footnote-mixin();
    font-weight: 700;
}


.caption-1-mixin {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: @text-high-contrast;
}
.text-caption-1 {
    .caption-1-mixin();
}
.text-caption-1-bold {
    .caption-1-mixin();
    font-weight: 700;
}


.caption-2-mixin {
    font-size: 12px;
    line-height: 12px; 
    font-weight: 400;
    color: @text-high-contrast;
}
.text-caption-2 {
    .caption-2-mixin();
}
.text-caption-2-bold {
    .caption-2-mixin();
    font-weight: 700;
}

.caption-3-mixin {
    font-size: 11px;
    line-height: 15px; 
    font-weight: 400;
    color: @text-high-contrast;
}
.text-caption-3 {
    .caption-3-mixin();
}
.text-caption-3-bold {
    .caption-3-mixin();
    font-weight: 700;
}



.text-high-contrast {
    color: @text-high-contrast;
}
.text-mid-contrast {
    color: @text-mid-contrast;
}
.text-low-contrast {
    color: @text-low-contrast;
}
.text-disabled {
    color: @text-disabled;
}
.text-primary-mid-contrast {
    color: @text-primary-mid-contrast;
}
.text-primary-high-contrast {
    color: @text-primary-high-contrast;
}
.text-white {
    color: @white;
}
