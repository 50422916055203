.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.PieChart {
  display: flex;
  align-items: center;
  height: 100%;
}
.PieChart .legend-container {
  flex: 1 1 70%;
  margin-left: 49px;
  margin-right: 64px;
  max-width: 340px;
}
.PieChart .legend-container .office:nth-child(odd) {
  background-color: #FAFAFA;
}
.PieChart .legend-container .office-group {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #1A1A1A;
  font-weight: 700;
  background-color: #E9E8FD;
  color: #2D0D8C;
}
.PieChart .legend-container .remote {
  background-color: #D4E6FC;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #1A1A1A;
  font-weight: 700;
  color: #0D51A5;
}
.PieChart .legend-container .legend-line {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 10px 20px;
  border-radius: 10px;
}
.PieChart .legend-container .legend-line .name {
  margin-right: 10px;
}
.PieChart .legend-container .legend-line.office {
  margin-bottom: 5px;
}
.PieChart .legend-container .legend-line.office .name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #1A1A1A;
}
.PieChart .legend-container .legend-line .score {
  margin-left: 10px;
}
.PieChart .legend-container .legend-line .legend-circle {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 5px;
}
.PieChart .legend-container .legend-line .trend {
  margin-left: auto;
  width: 45px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #1A1A1A;
  font-weight: 700;
}
.PieChart .legend-container .legend-line .trend.positive {
  color: #37BE6E;
}
.PieChart .legend-container .legend-line .trend.negative {
  color: #D7382D;
}
.PieChart .legend-container .legend-line .trend.stagnant {
  color: #CCCCCC;
}
.PieChart .recharts-wrapper {
  flex: 1 1 30%;
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper {
  outline: none;
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper .custom-tooltip {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 20px 40px rgba(47, 52, 55, 0.2);
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper .custom-tooltip .content {
  display: flex;
  align-items: center;
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper .custom-tooltip .content .name {
  margin-right: 10px;
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper .custom-tooltip .content .circle {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  margin-right: 10px;
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper .custom-tooltip .content .circle.remote {
  background-color: #5A9DF2;
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper .custom-tooltip .content .circle.office {
  background-color: #6B40ED;
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper .custom-tooltip .content .percentage {
  margin-left: 10px;
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper .custom-tooltip .content .trend.positive {
  color: #37BE6E;
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper .custom-tooltip .content .trend.negative {
  color: #D7382D;
}
.PieChart .recharts-wrapper .recharts-tooltip-wrapper .custom-tooltip .content .trend.stagnant {
  color: #CCCCCC;
}
