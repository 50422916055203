
.Shadow-100-mixin {
    box-shadow: 0px 1.7px 3.9px rgba(0, 0, 0, 0.08), 0px 0px 0.8px rgba(0, 0, 0, 0.06);
}

.Shadow-100 {
    .Shadow-100-mixin();
}

.Shadow-300-mixin {
    box-shadow: 0px 2px 4px rgba(47, 52, 55, 0.1), 0px 0px 1px rgba(47, 52, 55, 0.08);
}

.Shadow-300 {
    .Shadow-300-mixin();
}

.Shadow-500-mixin {
    box-shadow: 0px 4px 8px rgba(47, 52, 55, 0.1), 0px 1px 2px rgba(47, 52, 55, 0.1);
}

.Shadow-500 {
    .Shadow-500-mixin();
}

.Shadow-700-mixin {
    box-shadow: 0px 6px 10px rgba(47, 52, 55, 0.12), 0px 2px 4px rgba(47, 52, 55, 0.05);
}

.Shadow-700 {
    .Shadow-700-mixin();
}

.Shadow-900-mixin {
    box-shadow: 0px 8px 17px rgba(47, 52, 55, 0.2);
}

.Shadow-900 {
    .Shadow-900-mixin();
}