.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.SideNavigation {
  height: 100%;
}
.SideNavigation.ant-layout-sider {
  z-index: 1000;
  position: absolute;
  transition: all 0.2s ease-in-out;
}
.SideNavigation.collapsed-true .ant-menu-item {
  padding-left: 13px !important;
}
.SideNavigation .logo-container {
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
}
.SideNavigation .logo-container .logo {
  width: 123px;
}
.SideNavigation .logo-container:hover {
  cursor: pointer;
}
.SideNavigation .logo-container.small .logo {
  width: 29px;
  height: 25px;
}
.SideNavigation .ant-divider {
  margin: 0;
  margin-bottom: 40px;
}
.SideNavigation .schedule-btn {
  margin: 0 22px;
}
.SideNavigation .schedule-btn.small {
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
}
.SideNavigation .schedule-btn:hover {
  cursor: pointer;
}
.SideNavigation.ant-layout-sider {
  background: #ffffff;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  width: inherit;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu {
  margin-bottom: 40px;
  border: 0;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-sub {
  margin-bottom: 10px;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item:hover,
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu:hover,
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu-title:hover {
  color: #2F84EE;
  background-color: #F3F3F3;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu {
  background-color: #FFFFFF;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu .ant-menu-sub {
  background-color: #FFFFFF;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu.ant-menu-submenu-selected {
  color: #1A1A1A;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu:hover {
  color: #1A1A1A;
  background-color: #FFFFFF;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu:hover .ant-menu-submenu-arrow {
  color: #1A1A1A;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  width: 100%;
  background-color: #FFFFFF;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: #F3F3F3 !important;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
  color: #1A1A1A;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .anticon svg path {
  fill: currentColor;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item-selected {
  background-color: #ECF4FE !important;
  color: #2F84EE !important;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item-selected:after {
  display: none;
}
.SideNavigation.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item-selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 44px;
  background: #2F84EE;
}
.collapsed-true .logo-container .logo {
  width: 29px;
}
