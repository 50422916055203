.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal-form {
  height: 100%;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal-form.grey {
  background-color: #F3F3F3;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal {
  background-color: #FFFFFF;
  border-radius: 5px;
  display: flex;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .time-period-container {
  transition: transform 0.2s ease-in-out;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .time-period-container.fade-out {
  transform: translate(-640px);
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .space-viewer-time-period-container {
  transition: transform 0.2s ease-in-out;
  transform: translate(-640px);
  min-width: 640px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .ant-tabs-nav {
  margin-bottom: 20px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .ant-tabs-nav .ant-tabs-nav-list {
  padding-left: 40px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .mb-30 {
  margin-bottom: 30px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .px-20 {
  padding: 0 20px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .pl-40 {
  padding-left: 40px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .off-hour-container .text {
  margin-right: 10px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .section-title-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .section-title-container .title {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #1A1A1A;
  color: #737373;
  margin-left: 16px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .section-title-container .blue-icon-container {
  width: 24px;
  height: 24px;
  background-color: #D4E6FC;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .section-title-container .replicate {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #1A1A1A;
  margin-right: 10px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .section-title-container .replicate.disabled {
  color: #CCCCCC;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .date-time-container .controls-container {
  display: flex;
  column-gap: 10px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .date-time-container .controls-container .control {
  display: flex;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .date-time-container .controls-container .control .day {
  width: 145px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .date-time-container .controls-container .control .day div[class*="control"] {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid #F3F3F3;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .date-time-container .controls-container .control .day:hover {
  border-right: 1px solid #5aa7fa;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .date-time-container .controls-container .control .TimePickerDropdown {
  width: 130px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .date-time-container .controls-container .control .TimePickerDropdown .SingleSelect div[class*="control"] {
  border-radius: 0 5px 5px 0;
  border-left: 1px solid #F3F3F3;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .date-time-container .controls-container .control .TimePickerDropdown .SingleSelect:hover {
  border-left: 1px solid #5aa7fa;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .work-type-container {
  display: flex;
  margin-bottom: 10px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .work-type-container .CustomRadioButton {
  flex: 1 1 50%;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .work-type-container .CustomRadioButton:last-child {
  margin-left: 18px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .replicate-container {
  margin-bottom: 10px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .dropdown-space-viewer {
  display: flex;
  align-items: center;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .dropdown-space-viewer .SingleSelect {
  flex: 1 0 auto;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .dropdown-space-viewer .SingleSelect div[class*="control"] {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid #CCCCCC;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .dropdown-space-viewer .space-viewer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;
  border-radius: 0px 5px 5px 0px;
  height: 52px;
  width: 39px;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .dropdown-space-viewer .space-viewer-btn.enabled:hover {
  cursor: pointer;
  background-color: #7953EF;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .dropdown-space-viewer .space-viewer-btn.enabled:hover .anticon path {
  fill: #ffffff;
}
.PreferenceTimePeriodModalWrapper .ant-modal .TimePeriodModal .dropdown-space-viewer .space-viewer-btn.disabled .anticon path {
  fill: #CCCCCC;
}
.PreferenceTimePeriodModalWrapper .ant-modal .SpaceViewerModalContent {
  height: 100%;
  background-color: #F3F3F3;
}
.PreferenceTimePeriodModalWrapper .ant-modal .space-column .ant-form-item-explain-error {
  margin-right: 39px;
}
