.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.SpaceViewerDesk {
  min-width: 42px;
  height: 23px;
  border-radius: 5px;
  position: relative;
}
.SpaceViewerDesk.empty {
  background-color: #E1FAEB;
  border: 1px solid #59CF89;
}
.SpaceViewerDesk.empty .img,
.SpaceViewerDesk.empty .no-img {
  border: 1px solid #59CF89;
}
.SpaceViewerDesk.empty .img-container {
  background-color: #ffffff;
}
.SpaceViewerDesk.occupied {
  background-color: #D4E6FC;
  border: 1px solid #5A9DF2;
}
.SpaceViewerDesk.occupied .img,
.SpaceViewerDesk.occupied .no-img {
  border: 1px solid #5A9DF2;
}
.SpaceViewerDesk.occupied .img-container {
  background-color: #D4E6FC;
}
.SpaceViewerDesk.teamMember {
  background-color: #E9E8FD;
  border: 1px solid #6B40ED;
}
.SpaceViewerDesk.teamMember .img,
.SpaceViewerDesk.teamMember .no-img {
  border: 1px solid #6B40ED;
}
.SpaceViewerDesk.teamMember .img-container {
  background-color: #E9E8FD;
}
.SpaceViewerDesk.favourite {
  background-color: #FBEFD0;
  border: 1px solid #F2C552;
}
.SpaceViewerDesk.favourite .img,
.SpaceViewerDesk.favourite .no-img {
  border: 1px solid #F2C552;
}
.SpaceViewerDesk.favourite .img-container {
  background-color: #FBEFD0;
}
.SpaceViewerDesk.currentUser {
  background-color: #D4E6FC;
  border: 1px solid #5A9DF2;
}
.SpaceViewerDesk.currentUser .img,
.SpaceViewerDesk.currentUser .no-img {
  border: 1px solid #5A9DF2;
}
.SpaceViewerDesk.currentUser .img-container {
  background-color: #D4E6FC;
}
.SpaceViewerDesk .img-container {
  width: 23px;
  height: 23px;
  border-radius: 50px;
  position: absolute;
  right: 21%;
}
.SpaceViewerDesk .img-container .img {
  width: 23px;
  height: 23px;
  border-radius: 50%;
}
.SpaceViewerDesk .img-container.no-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.SpaceViewerDesk.img-top .img-container {
  top: -15px;
}
.SpaceViewerDesk.img-top .icon {
  top: 6px;
}
.SpaceViewerDesk.img-bottom .img-container {
  bottom: -15px;
}
.SpaceViewerDesk.img-bottom .icon {
  bottom: 1px;
}
.SpaceViewerDesk .icon {
  position: absolute;
  right: 12px;
}
.SpaceViewerDesk .react-tooltip {
  z-index: 1000;
  padding: 10px;
}
.SpaceViewerDesk .react-tooltip .tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 303px;
}
.SpaceViewerDesk .react-tooltip .tooltip-content .top {
  display: flex;
  justify-content: center;
  align-items: center;
}
.SpaceViewerDesk .react-tooltip .tooltip-content .top .name {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #1A1A1A;
  font-weight: 700;
  color: #ffffff;
}
.SpaceViewerDesk .react-tooltip .tooltip-content .top .dot {
  margin: -2px 2px;
}
.SpaceViewerDesk .react-tooltip .tooltip-content .top .relationship {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #1A1A1A;
  color: #CCCCCC;
}
.SpaceViewerDesk .react-tooltip .tooltip-content .timePeriods {
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  color: #1A1A1A;
  color: #CCCCCC;
}
