.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.Button {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: #1A1A1A;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}
.Button::before {
  content: '';
}
.Button .icon-container {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.Button .icon-container .anticon svg,
.Button .icon-container .anticon img {
  width: 24px;
  height: 24px;
}
.Button .icon-container.leftIcon {
  margin-left: -4px;
  margin-right: 8px;
}
.Button .icon-container.rightIcon {
  margin-left: 8px;
  margin-right: -4px;
}
.Button .icon-container.leftCircleIcon {
  margin-left: -4px;
  margin-right: 8px;
}
.Button .icon-container.rightCircleIcon {
  margin-left: 8px;
  margin-right: -4px;
}
.Button.type-primary {
  width: 200px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  background-color: #2F84EE;
  color: #ffffff;
}
.Button.type-primary.disabled-false:hover {
  background-color: #5A9DF2;
}
.Button.type-primary.disabled-false:active {
  background-color: #0D51A5;
}
.Button.type-primary.disabled-true {
  background-color: #F3F3F3;
  color: #CCCCCC;
}
.Button.type-greenchecked {
  width: 200px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  background-color: #37BE6E;
  color: #ffffff;
}
@media (hover: hover) {
  .Button.type-greenchecked.disabled-false:hover {
    background-color: #E1FAEB;
  }
}
.Button.type-greenchecked.disabled-false:active {
  background-color: #E1FAEB;
}
.Button.type-greenchecked.disabled-true {
  background-color: #F3F3F3;
  color: #CCCCCC;
}
.Button.type-box {
  width: 200px;
  height: 50px;
  border: 1px solid #000000;
  border-radius: 50px;
  background-color: #ffffff;
  color: #000000;
}
.Button.type-box.disabled-false:hover {
  background-color: #F3F3F3;
}
.Button.type-box.disabled-false:active {
  background-color: #F3F3F3;
}
.Button.type-box.disabled-true {
  background-color: #F3F3F3;
  color: #CCCCCC;
}
.Button.type-secondary {
  width: 200px;
  height: 50px;
  border: 2px solid #D4E6FC;
  border-radius: 50px;
  background-color: #D4E6FC;
  color: #0D51A5;
}
.Button.type-secondary.disabled-false:hover {
  border-color: #5A9DF2;
}
.Button.type-secondary.disabled-false:active {
  background-color: #5A9DF2;
  color: #ffffff;
}
.Button.type-secondary.disabled-true {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  color: #CCCCCC;
}
.Button.type-tertiary {
  width: 200px;
  height: 50px;
  border: 2px solid #2F84EE;
  border-radius: 50px;
  background-color: transparent;
  color: #2F84EE;
}
.Button.type-tertiary.disabled-false:hover {
  background-color: #2F84EE;
  color: #ffffff;
}
.Button.type-tertiary.disabled-false:active {
  border-color: #0D51A5;
  background-color: #0D51A5;
  color: #ffffff;
}
.Button.type-tertiary.disabled-true {
  background-color: transparent;
  border-color: #F3F3F3;
  color: #CCCCCC;
}
.Button.type-light {
  width: 200px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  background-color: #F3F3F3;
  color: #1A1A1A;
}
.Button.type-light.disabled-false:hover {
  background-color: #FAFAFA;
}
.Button.type-light.disabled-false:active {
  background-color: #F3F3F3;
}
.Button.type-light.disabled-true {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  color: #CCCCCC;
}
.Button.type-small {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #1A1A1A;
  width: 200px;
  border: 0;
  border-radius: 50px;
  background-color: #6B40ED;
  color: #ffffff;
}
.Button.type-small.disabled-false:hover {
  background-color: #7953EF;
}
.Button.type-small.disabled-false:active {
  background-color: #7953EF;
}
.Button.type-small.disabled-true {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  color: #CCCCCC;
}
.Button.type-smallLight {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #1A1A1A;
  font-weight: 700;
  width: 200px;
  border: 1px solid #E9E8FD;
  border-radius: 50px;
  background-color: #E9E8FD;
  color: #6B40ED;
}
.Button.type-smallLight.disabled-false:hover {
  border-color: #7953EF;
}
.Button.type-smallLight.disabled-false:active {
  border-color: #7953EF;
  background-color: #7953EF;
  color: #ffffff;
}
.Button.type-smallLight.disabled-true {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  color: #CCCCCC;
}
.Button.type-floating {
  width: 200px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  background-color: #ffffff;
  color: #2F84EE;
  box-shadow: 0px 10px 20px rgba(47, 52, 55, 0.1), 0px 3px 6px rgba(47, 52, 55, 0.1);
}
.Button.type-floating .leftIcon {
  color: #737373;
}
.Button.type-floating .rightIcon {
  color: #737373;
}
.Button.type-floating .leftCircleIcon {
  background-color: #2F84EE;
  color: #ffffff;
}
.Button.type-floating .rightCircleIcon {
  background-color: #2F84EE;
  color: #ffffff;
}
.Button.type-floating.disabled-false:hover {
  background-color: #2F84EE;
  color: #ffffff;
}
.Button.type-floating.disabled-false:hover .leftIcon {
  color: #ffffff;
}
.Button.type-floating.disabled-false:hover .rightIcon {
  color: #ffffff;
}
.Button.type-floating.disabled-false:hover .leftCircleIcon {
  background-color: #ffffff;
  color: #2F84EE;
}
.Button.type-floating.disabled-false:hover .rightCircleIcon {
  background-color: #ffffff;
  color: #2F84EE;
}
.Button.type-floating.disabled-false:active {
  background-color: #0D51A5;
  color: #ffffff;
}
.Button.type-floating.disabled-false:active .leftIcon {
  color: #ffffff;
}
.Button.type-floating.disabled-false:active .rightIcon {
  color: #ffffff;
}
.Button.type-floating.disabled-false:active .leftCircleIcon {
  background-color: #ffffff;
  color: #0D51A5;
}
.Button.type-floating.disabled-false:active .rightCircleIcon {
  background-color: #ffffff;
  color: #0D51A5;
}
.Button.type-floating.disabled-true {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  color: #CCCCCC;
}
.Button.type-floating.disabled-true .leftIcon {
  color: #CCCCCC;
}
.Button.type-floating.disabled-true .rightIcon {
  color: #CCCCCC;
}
.Button.type-floating.disabled-true .leftCircleIcon {
  background-color: #ffffff;
  color: #F3F3F3;
}
.Button.type-floating.disabled-true .rightCircleIcon {
  background-color: #ffffff;
  color: #F3F3F3;
}
.Button.type-link {
  width: 200px;
  height: auto;
  border: 0;
  background-color: transparent;
  color: #2F84EE;
}
.Button.type-link.width-hugged {
  padding: 0;
}
.Button.type-link .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}
.Button.type-link .leftIcon svg {
  width: 32px;
  height: 32px;
}
.Button.type-link .rightIcon svg {
  width: 32px;
  height: 32px;
}
.Button.type-link .leftCircleIcon {
  background-color: #2F84EE;
  color: #ffffff;
}
.Button.type-link .rightCircleIcon {
  background-color: #2F84EE;
  color: #ffffff;
}
.Button.type-link.disabled-false:hover {
  color: #5A9DF2;
}
.Button.type-link.disabled-false:hover .leftCircleIcon {
  background-color: #5A9DF2;
}
.Button.type-link.disabled-false:hover .rightCircleIcon {
  background-color: #5A9DF2;
}
.Button.type-link.disabled-false:active {
  color: #0D51A5;
}
.Button.type-link.disabled-false:active .leftCircleIcon {
  background-color: #0D51A5;
}
.Button.type-link.disabled-false:active .rightCircleIcon {
  background-color: #0D51A5;
}
.Button.type-link.disabled-true {
  color: #CCCCCC;
}
.Button.type-link.disabled-true .leftCircleIcon {
  background-color: #CCCCCC;
}
.Button.type-link.disabled-true .rightCircleIcon {
  background-color: #CCCCCC;
}
.Button:active.disabled-false {
  transition: none;
}
.Button:active.disabled-false .icon-container {
  transition: none;
}
.Button.width-hugged {
  width: fit-content;
  padding: 0 18px;
}
.Button.width-auto {
  width: auto;
}
.Button.width-full {
  width: 100%;
}
.Button.height-small {
  height: 35px;
}
.Button.disabled-false {
  cursor: pointer;
}
.Button.disabled-true {
  cursor: default;
}
