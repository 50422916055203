.collapse-animated-arrow {
    .ant-collapse-arrow {
        span {
            transition: .5s ease-in-out;
        }
    }

    .ChevronDownIcon-open-true {
        span {
            transform: rotate(180deg);
        }
    }

    .ChevronDownIcon-open-false {
        span {
            transform: rotate(360deg);
        }
    }
}