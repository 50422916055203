.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.WeeklyHourPrefCalendar {
  height: 100%;
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
}
.WeeklyHourPrefCalendar.rounded-top .rbc-time-view,
.WeeklyHourPrefCalendar.rounded-top .rbc-time-header {
  border-radius: 10px 10px 0 0;
}
.WeeklyHourPrefCalendar .rbc-time-header {
  margin-right: 0 !important;
  border-right: 0;
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  background-color: white;
  top: 0;
  height: 40px;
  box-shadow: 0px 6px 10px rgba(47, 52, 55, 0.12), 0px 2px 4px rgba(47, 52, 55, 0.05);
}
.WeeklyHourPrefCalendar .rbc-time-header .rbc-time-header-content {
  justify-content: center;
  border-left: 0;
}
.WeeklyHourPrefCalendar .rbc-time-header .rbc-time-header-content .rbc-header {
  border-bottom: 0;
  border-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: #1A1A1A;
}
.WeeklyHourPrefCalendar .rbc-time-header .rbc-time-header-content .rbc-header .customDateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.WeeklyHourPrefCalendar .rbc-time-header .rbc-time-header-content .rbc-header .customDateHeader .anticon {
  margin-left: 10px;
}
.WeeklyHourPrefCalendar .rbc-time-header .rbc-time-header-content .rbc-header .customDateHeader .date-number {
  margin-left: 5px;
}
.WeeklyHourPrefCalendar .rbc-time-header .rbc-time-header-content .rbc-header .customDateHeader .current-date {
  background-color: #2F84EE;
  color: #ffffff;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.WeeklyHourPrefCalendar .rbc-time-header-gutter {
  display: flex;
  justify-content: center;
}
.WeeklyHourPrefCalendar .rbc-time-header-gutter .customGutterHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
}
.WeeklyHourPrefCalendar .rbc-time-header-gutter .customGutterHeader .anticon {
  margin-right: 5px;
}
.WeeklyHourPrefCalendar .rbc-allday-cell {
  display: none;
}
.WeeklyHourPrefCalendar .rbc-time-gutter {
  width: 82px;
}
.WeeklyHourPrefCalendar .rbc-time-content {
  border-top: 0;
}
.WeeklyHourPrefCalendar .rbc-timeslot-group {
  border-color: #F3F3F3;
  border-left: 1px solid #F3F3F3;
}
.WeeklyHourPrefCalendar .rbc-timeslot-group .rbc-time-slot {
  border-top: 0;
}
.WeeklyHourPrefCalendar .rbc-timeslot-group .rbc-time-slot:hover {
  cursor: pointer;
}
.WeeklyHourPrefCalendar .rbc-timeslot-group .rbc-time-slot .rbc-label {
  color: #737373 !important;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #1A1A1A;
}
.WeeklyHourPrefCalendar .rbc-day-slot .rbc-timeslot-group .restricted {
  background-color: #F3F3F3;
  opacity: 0.5;
}
.WeeklyHourPrefCalendar .rbc-time-view {
  border-top: 0;
}
.WeeklyHourPrefCalendar .rbc-today {
  background-color: transparent;
}
.WeeklyHourPrefCalendar .rbc-current-time-indicator {
  display: none;
}
.WeeklyHourPrefCalendar .rbc-time-content::-webkit-scrollbar {
  display: none;
}
.WeeklyHourPrefCalendar .rbc-time-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.WeeklyHourPrefCalendar .rbc-events-container {
  margin-right: 0;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event {
  padding: 4px 10px;
  min-height: 5px;
  width: 100% !important;
  left: 0% !important;
  border: 0;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event.quarterHourEvent {
  padding-top: 0;
  padding-bottom: 0;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event:hover {
  cursor: default;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event .rbc-event-label {
  display: none;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event.core-hour-displayed {
  width: calc(100% - 5px) !important;
  left: 5px !important;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event.pref-type-office {
  background-color: #E9E8FD;
  color: #2D0D8C;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event.pref-type-offhour {
  background-color: #FBEFD0;
  color: #725409;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event.pref-type-remote {
  background-color: #D4E6FC;
  color: #0D51A5;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event.pref-type-coreHour {
  background: transparent;
  left: 0 !important;
  width: 5px !important;
  padding: 0;
  border-radius: 5px 0 0 5px;
  cursor: default;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event.pref-type-coreHour .custom-event-inner-container {
  cursor: default;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event.pref-type-coreHour .custom-event-inner-container .core-hour-blueline-indicator {
  cursor: default;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2F84EE;
  transition: background 300ms;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event.pref-type-coreHour .custom-event-inner-container .core-hour-blueline-indicator:hover {
  background: #5A9DF2;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event .custom-event-inner-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event .custom-event-inner-container .warning .anticon {
  margin-right: 5px;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event .custom-event-inner-container .capacity {
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  color: #1A1A1A;
  float: right;
  margin-left: 5px;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event .custom-event-inner-container .office {
  font-weight: 400;
  padding-top: 2px;
}
.WeeklyHourPrefCalendar .rbc-events-container .rbc-event .custom-event-inner-container .time-string {
  margin-left: 2px;
  padding-top: 2px;
}
