.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.DashboardToday {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  align-items: center;
  padding: 20px;
  background: linear-gradient(122.34deg, #6B40ED 0%, #9373F2 81.29%);
  border-radius: 8px;
}
.DashboardToday > div {
  position: relative;
  z-index: 1;
}
.DashboardToday .profile {
  display: flex;
  align-items: center;
  gap: 10px;
}
.DashboardToday .profile .img-container {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  border: 3px solid #ffffff;
  background-color: #ffffff;
  flex-shrink: 0;
}
.DashboardToday .profile .text {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: #1A1A1A;
  font-weight: 700;
  color: #ffffff;
}
.DashboardToday .info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
.DashboardToday .info-container .title {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #1A1A1A;
  color: #ffffff;
}
.DashboardToday .info-container .content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.DashboardToday .info-container .content .info-bloc {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #1A1A1A;
  font-weight: 700;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 5px 10px;
  background: rgba(110, 83, 220, 0.6);
  border-radius: 60px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.DashboardToday .info-container .content .info-bloc:hover,
.DashboardToday .info-container .content .info-bloc.ant-tooltip-open {
  background-color: #6B40ED;
  box-shadow: 0px 10px 20px rgba(47, 52, 55, 0.1), 0px 3px 6px rgba(47, 52, 55, 0.1);
}
.DashboardToday .info-container .content .info-bloc > .anticon {
  margin-right: 10px;
}
.DashboardToday .info-container .content .info-bloc > .anticon svg {
  width: 34px;
  height: auto;
}
.DashboardToday .info-container .content .info-bloc > .anticon svg path {
  fill: currentColor;
}
.DashboardToday .info-container .content .work-hours-container {
  display: flex;
  align-items: center;
}
.DashboardToday .info-container .content .work-hours-container .anticon {
  margin-right: 10px;
}
.DashboardToday .waves-container {
  overflow: hidden;
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}
.DashboardToday .waves-container .waves {
  bottom: -90px;
  left: -50px;
  opacity: 0.3;
  transform: rotate(16deg);
  animation: wave-1 20s ease-in-out infinite alternate;
}
@keyframes wave-1 {
  100% {
    transform: rotate(16deg) translate(-15%, 0);
  }
}
@keyframes wave-2 {
  100% {
    transform: translate(40%, 0);
  }
}
@keyframes wave-3 {
  100% {
    transform: translate(-10%, 0) rotateY(180deg);
  }
}
.DashboardToday .waves-container .waves,
.DashboardToday .waves-container .waves::before,
.DashboardToday .waves-container .waves::after {
  display: block;
  position: absolute;
  z-index: 0;
  width: 430px;
  height: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  background-image: url('/Assets/Images/transparent-wave.svg');
}
.DashboardToday .waves-container .waves::before {
  content: '';
  animation: wave-2 20s ease-in-out infinite alternate;
  bottom: -24px;
  left: -70px;
}
.DashboardToday .waves-container .waves::after {
  content: '';
  animation: wave-3 20s ease-in-out infinite alternate;
  bottom: 5px;
  left: -40px;
  transform: rotateY(180deg);
}
@media (min-width: 1280px) {
  .DashboardToday {
    grid-template-columns: 200px 1fr;
  }
  .DashboardToday .profile {
    display: block;
  }
  .DashboardToday .profile .img-container {
    width: 110px;
    height: 110px;
    margin: 0 auto 10px auto;
  }
  .DashboardToday .profile .text {
    text-align: center;
  }
  .DashboardToday .profile .text span {
    display: block;
  }
}
