@import (reference) '~antd/dist/antd.less';
@import (reference) '/src/Style/typographie.less';

.table-striped-rows {
  tbody tr:nth-child(2n) td {
    background-color: @layout-super-light;
  }

  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    a {
      color: @primary-color;
    }
  }
}

.table-action-rows {

  .ant-table th.ant-table-cell,
  .ant-table .ant-table-column-title {
    background-color: @primary-low-contrast;
    color: @primary-high-contrast !important;
  }

  .ant-table th.ant-table-cell,
  .ant-table .ant-table-column-sorter {
    color: @primary-high-contrast !important;
  }

  .table-row-light {
    background-color: #fafafa;
  }

  tbody tr {
    cursor: pointer;
  }
}

.ant-table-column-sorters {
  justify-content: space-between;
  width: 100%;
}

.ant-table {
  border-radius: 10px;
  overflow-x: auto;
  .Shadow-500-mixin();

  th.ant-table-cell,
  //columns without sorter
  .ant-table-column-title {
    .callout-mixin();
    font-weight: 700;
  }

  td.ant-table-cell {
    padding: 6px 16px
  }

  td.ant-table-cell {
    height: 55px;
  }
}

.ant-table-thead th.ant-table-column-sort {
  //keep same th style when column has active sort direction
  background: #fafafa;
}


td.ant-table-column-sort {
  //keep same td style when column has active sort direction
  background: none;
}

.ant-pagination {
  .ant-pagination-item {
    font-weight: bold;
    font-size: @font-size-sm;
    min-width: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4px;

    &.ant-pagination-item-active {
      background-color: @primary-mid-contrast;

      a {
        color: @white;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 40px;
    width: 40px;
    height: 40px;

    button {
      border: none;
      border-radius: 4px;
    }

    .anticon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-pagination-options {
    height: 40px;

    .ant-select-selector {
      border: none;
      border-radius: 4px;
    }

    .ant-pagination-options-size-changer,
    .ant-select-selector {
      height: 100%;

      .ant-select-selection-item {
        line-height: 3;
      }
    }
  }
}

//wierd line between head columns when sortable
.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}