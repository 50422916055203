.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.WeeklyScheduleCalendar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
}
.WeeklyScheduleCalendar .rbc-time-header {
  margin-right: 0 !important;
  border-right: 0;
  height: 35px;
  box-shadow: 0px 6px 10px rgba(47, 52, 55, 0.12), 0px 2px 4px rgba(47, 52, 55, 0.05);
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content {
  justify-content: center;
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content .rbc-time-header-cell {
  align-items: center;
  height: 100%;
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content .rbc-header {
  border-bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: #1A1A1A;
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content .rbc-header a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content .rbc-header a :hover {
  color: #1A1A1A;
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content .rbc-header .customDateHeader {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-transform: capitalize;
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content .rbc-header .customDateHeader .date-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  border-bottom: 1px solid #F3F3F3;
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content .rbc-header .customDateHeader .date-container .anticon {
  margin-left: 10px;
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content .rbc-header .customDateHeader .date-container .anticon svg {
  width: 24px;
  height: 24px;
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content .rbc-header .customDateHeader .date-container .date-number {
  margin-left: 5px;
}
.WeeklyScheduleCalendar .rbc-time-header .rbc-time-header-content .rbc-header .customDateHeader .date-container .current-date {
  background-color: #2F84EE;
  color: #ffffff;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.WeeklyScheduleCalendar .rbc-time-header-gutter {
  display: flex;
  justify-content: center;
  width: 160px;
  padding: 0;
}
.WeeklyScheduleCalendar .rbc-time-header-gutter .customGutterHeader {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.WeeklyScheduleCalendar .rbc-time-header-gutter .customGutterHeader .utc-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #F3F3F3;
  padding-right: 20px;
  height: 100%;
}
.WeeklyScheduleCalendar .rbc-time-header-gutter .customGutterHeader .utc-container .anticon {
  margin-right: 2px;
}
.WeeklyScheduleCalendar .rbc-time-header-gutter .customGutterHeader .away-container {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin-left: auto;
  padding-right: 20px;
}
.WeeklyScheduleCalendar .rbc-allday-cell {
  display: none;
}
.WeeklyScheduleCalendar .rbc-time-gutter {
  width: 160px;
}
.WeeklyScheduleCalendar .rbc-time-content {
  border-top: 0;
}
.WeeklyScheduleCalendar .rbc-timeslot-group {
  border-color: #F3F3F3;
  border-left: 1px solid #F3F3F3;
}
.WeeklyScheduleCalendar .rbc-timeslot-group .rbc-time-slot {
  border-top: 0;
}
.WeeklyScheduleCalendar .rbc-timeslot-group .rbc-time-slot:hover {
  cursor: pointer;
}
.WeeklyScheduleCalendar .rbc-timeslot-group .rbc-time-slot .rbc-label {
  color: #737373 !important;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #1A1A1A;
}
.WeeklyScheduleCalendar .rbc-day-slot .rbc-timeslot-group .restricted {
  background-color: #F3F3F3;
  opacity: 0.5;
}
.WeeklyScheduleCalendar .rbc-time-view {
  border-top: 0;
  border-color: #F3F3F3;
}
.WeeklyScheduleCalendar .rbc-today {
  background-color: transparent;
}
.WeeklyScheduleCalendar .rbc-current-time-indicator {
  display: none;
}
.WeeklyScheduleCalendar .rbc-time-content::-webkit-scrollbar {
  display: none;
}
.WeeklyScheduleCalendar .rbc-time-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.WeeklyScheduleCalendar .rbc-events-container {
  margin-right: 0;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event {
  padding: 4px 10px;
  min-height: 5px;
  width: 100% !important;
  left: 0% !important;
  border: 0;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event.quarterHourEvent {
  padding-top: 0;
  padding-bottom: 0;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event:hover {
  cursor: default;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event .rbc-event-label {
  display: none;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event.core-hour-displayed {
  width: calc(100% - 5px) !important;
  left: 5px !important;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event.pref-type-office {
  background-color: #E9E8FD;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event.pref-type-offhour {
  background-color: #FBEFD0;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event.pref-type-remote {
  background-color: #D4E6FC;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event.pref-type-away {
  background-color: #E6E6E6;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event.pref-type-coreHour {
  background: transparent;
  left: 0 !important;
  width: 5px !important;
  padding: 0;
  border-radius: 5px 0 0 5px;
  cursor: default;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event.pref-type-coreHour .custom-event-inner-container {
  cursor: default;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event.pref-type-coreHour .custom-event-inner-container .core-hour-blueline-indicator {
  cursor: default;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2F84EE;
  transition: background 300ms;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event.pref-type-coreHour .custom-event-inner-container .core-hour-blueline-indicator:hover {
  background: #5A9DF2;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event .custom-event-inner-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event .custom-event-inner-container .warning .anticon {
  margin-right: 5px;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event .custom-event-inner-container .capacity {
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  color: #1A1A1A;
  float: right;
  margin-left: 5px;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event .custom-event-inner-container .office {
  font-weight: 400;
  padding-top: 2px;
}
.WeeklyScheduleCalendar .rbc-events-container .rbc-event .custom-event-inner-container .time-string {
  margin-left: 2px;
  padding-top: 2px;
}
.WeeklyScheduleCalendar .customToolbar {
  border: 1px solid #F3F3F3;
  border-right: 0px;
}
.WeeklyScheduleCalendar .customToolbar .Skeleton {
  padding: 0 10px !important;
}
.WeeklyScheduleCalendar .customToolbar .customToolbar-bottom {
  padding: 10px 19px;
  border-top: 1px solid #F3F3F3;
  display: flex;
  align-items: flex-start;
}
@media (min-width: 1580px) {
  .WeeklyScheduleCalendar .customToolbar .customToolbar-bottom {
    padding: 15px 19px;
    align-items: center;
  }
}
.WeeklyScheduleCalendar .customToolbar .customToolbar-bottom .flex-wrap-container {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  row-gap: 26px;
  align-items: center;
  padding-top: 13px;
}
@media (min-width: 1580px) {
  .WeeklyScheduleCalendar .customToolbar .customToolbar-bottom .flex-wrap-container {
    padding-top: 0;
  }
}
.WeeklyScheduleCalendar .customToolbar .customToolbar-bottom .flex-wrap-container .date-change-buttons-container {
  display: flex;
  flex: 1 1 auto;
}
.WeeklyScheduleCalendar .customToolbar .customToolbar-bottom .flex-wrap-container .date-change-buttons-container > :hover {
  cursor: pointer;
}
.WeeklyScheduleCalendar .customToolbar .customToolbar-bottom .flex-wrap-container .date-change-buttons-container .back {
  margin-left: 14px;
}
.WeeklyScheduleCalendar .customToolbar .customToolbar-bottom .flex-wrap-container .date-change-buttons-container > div {
  display: flex;
  align-items: center;
}
.WeeklyScheduleCalendar .customToolbar .customToolbar-bottom .visual-filter-container {
  margin-right: 20px;
}
.create-period-ant-dropdown-overlay-class .ant-dropdown-menu {
  border-radius: 10px;
  width: 240px;
}
.create-period-ant-dropdown-overlay-class .ant-dropdown-menu .ant-dropdown-menu-item {
  border-top: 1px solid #F3F3F3;
}
.create-period-ant-dropdown-overlay-class .ant-dropdown-menu li:first-child {
  border-top: 0;
}
