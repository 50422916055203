.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #D7382D;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #D7382D;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.HappeningIndicator {
  position: relative;
  width: 84px;
  height: 84px;
}
.HappeningIndicator .anticon {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
  padding: 5px;
  border-radius: 50%;
  background-color: #6B40ED;
  color: #ffffff;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}
.HappeningIndicator .anticon svg {
  width: 21px;
  height: auto;
}
.HappeningIndicator .anticon:hover {
  transform: scale(1.2);
}
.HappeningIndicator .anticon:hover + .happening-shapes {
  border-radius: 50%;
  width: 64px;
  height: 64px;
}
.HappeningIndicator .anticon:hover + .happening-shapes:before {
  transform: rotate(225deg);
}
.HappeningIndicator .anticon:hover + .happening-shapes:after {
  transform: rotate(-315deg);
}
.HappeningIndicator .happening-shapes {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 84px;
  overflow: hidden;
  border-radius: 0 10px 0 0;
  transition: all 0.2s ease-in-out;
}
.HappeningIndicator .happening-shapes:before,
.HappeningIndicator .happening-shapes:after {
  content: '';
  z-index: 0;
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: #7953EF;
  opacity: 0.1;
  transform-origin: 0 60px;
  transition: transform 0.2s ease-in-out;
}
.HappeningIndicator .happening-shapes:before {
  top: -30px;
  right: -90px;
  transform: rotate(300deg);
}
.HappeningIndicator .happening-shapes:after {
  top: -30px;
  right: -90px;
  transform: rotate(-400deg);
}
